import React, { memo, useState, useCallback } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { ExternalLink, Tag as TagIcon, Calendar, Clock, ArrowRight } from 'react-feather';
import { NodeData, nodeColors } from './types';
import NotePreview from './NotePreview';

interface NoteNodeProps extends NodeProps<NodeData> {
  isConnectable: boolean;
}

const NoteNode: React.FC<NoteNodeProps> = memo(({ data, isConnectable, selected }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [previewTimeout, setPreviewTimeout] = useState<NodeJS.Timeout | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Get first line as title, rest as preview
  const lines = data.label.split('\n');
  const title = lines[0];
  const cleanTitle = title.replace(/^\[\[(.*?)\]\]$/, '$1').trim();
  const preview = lines.slice(1).join(' ').trim();

  // Format date if available
  const formatDate = useCallback((dateString?: string) => {
    if (!dateString) return '';
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
    } catch (err) {
      console.error('Error formatting date:', err);
      return '';
    }
  }, []);

  const handleMouseEnter = useCallback((e: React.MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
    const timeout = setTimeout(() => {
      setShowPreview(true);
    }, 500);
    setPreviewTimeout(timeout);
  }, []);

  const handleMouseMove = useCallback((e: React.MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (previewTimeout) {
      clearTimeout(previewTimeout);
      setPreviewTimeout(null);
    }
    setShowPreview(false);
  }, [previewTimeout]);

  const handleRemoveReference = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      setIsLoading(true);
      // Remove reference by dispatching custom event
      const event = new CustomEvent('removeReference', {
        detail: { noteId: data.noteId }
      });
      window.dispatchEvent(event);
    } catch (err) {
      setError('Failed to remove reference');
      console.error('Error removing reference:', err);
    } finally {
      setIsLoading(false);
    }
  }, [data.noteId]);

  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        className={`w-3 h-3 border-2 transition-colors duration-200
          ${selected ? 'border-blue-500 !bg-blue-200' : 'border-white !bg-gray-300'}
          dark:border-gray-800`}
      />
      <div
        className={`
          relative px-4 py-3 shadow-lg rounded-lg border
          transition-all duration-200 ease-in-out group
          ${selected ? 'border-blue-500 shadow-blue-100 dark:shadow-blue-900/20' : 'border-gray-200 dark:border-gray-700'}
          hover:shadow-xl hover:scale-105
          bg-white dark:bg-gray-800
          ${isLoading ? 'opacity-70 cursor-wait' : ''}
          ${error ? 'border-red-500 dark:border-red-700' : ''}
        `}
        style={{ width: '280px' }}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        {error && (
          <div className="absolute -top-8 left-0 right-0 text-center text-sm text-red-500 dark:text-red-400 bg-white dark:bg-gray-800 p-1 rounded-t-lg">
            {error}
          </div>
        )}
        {/* Header */}
        <div className="flex items-center gap-2 mb-2">
          <div
            className="w-2 h-2 rounded-full flex-shrink-0"
            style={{ backgroundColor: nodeColors[data.section] }}
          />
          <div className="flex-1 font-medium text-gray-900 dark:text-white truncate">
            {cleanTitle}
          </div>
          <ExternalLink 
            size={16} 
            className={`
              flex-shrink-0 transition-colors duration-200
              ${selected ? 'text-blue-500' : 'text-gray-400 dark:text-gray-500'}
              hover:text-blue-600 dark:hover:text-blue-400
            `}
          />
          {data.section === 'references' && (
            <button
              onClick={handleRemoveReference}
              className="ml-2 text-xs text-red-500 hover:text-red-700"
            >
              ×
            </button>
          )}
        </div>

        {/* Preview text */}
        {preview && (
          <div className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 mt-1">
            {preview}
          </div>
        )}

        {/* Metadata */}
        {(data.created_at || data.last_modified) && (
          <div className="flex items-center gap-3 mt-2 text-xs text-gray-400 dark:text-gray-500">
            {data.created_at && (
              <div className="flex items-center gap-1">
                <Calendar size={12} />
                {formatDate(data.created_at)}
              </div>
            )}
            {data.last_modified && (
              <div className="flex items-center gap-1">
                <Clock size={12} />
                {formatDate(data.last_modified)}
              </div>
            )}
            {data.tags && data.tags.length > 0 && (
              <div className="flex items-center gap-1">
                <TagIcon size={12} />
                {data.tags.length}
              </div>
            )}
          </div>
        )}

        {/* Drag Indicator */}
        <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-4 h-8 flex items-center justify-center">
          <ArrowRight 
            size={16} 
            className={`
              text-gray-400 opacity-0 transition-opacity duration-200
              group-hover:opacity-100 ${selected ? 'opacity-100' : ''}
            `}
          />
        </div>

        {/* Preview Popup */}
        {showPreview && (
          <NotePreview
            content={data.label}
            tags={data.tags}
            created_at={data.created_at}
            last_modified={data.last_modified}
            position={mousePosition}
          />
        )}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        className="w-3 h-3 !bg-gray-300 border-2 border-white"
      />
    </>
  );
});

export default NoteNode;
