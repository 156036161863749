import React, { useCallback, useMemo, useState } from 'react';
import { ChevronRight, ChevronDown, Menu, AlignLeft, ChevronLeft } from 'react-feather';
import { TagEditor } from '../TagEditor/TagEditor';

interface TableOfContentsProps {
  content: string;
  onHeaderClick: (index: number) => void;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
  note?: any;
  onTagAdd?: (tag: string) => void;
  onTagRemove?: (tag: string) => void;
  onTagsChange?: (tags: string[]) => void;
}

interface Header {
  text: string;
  level: number;
  index: number;
  children: Header[];
}

const extractHeaders = (content: string): Header[] => {
  const lines = content.split('\n');
  const headers: Header[] = [];
  const headerStack: Header[] = [];
  
  lines.forEach((line, index) => {
    const headerMatch = line.match(/^(#{1,6})\s+(.+)$/);
    if (headerMatch) {
      const header = {
        text: headerMatch[2].trim(),
        level: headerMatch[1].length,
        index: index,
        children: [],
      };

      while (
        headerStack.length > 0 &&
        headerStack[headerStack.length - 1].level >= header.level
      ) {
        headerStack.pop();
      }

      if (headerStack.length === 0) {
        headers.push(header);
      } else {
        headerStack[headerStack.length - 1].children.push(header);
      }

      headerStack.push(header);
    }
  });
  
  return headers;
};

export const TableOfContents: React.FC<TableOfContentsProps> = ({
  content,
  onHeaderClick,
  isCollapsed,
  onToggleCollapse,
  note,
  onTagAdd,
  onTagRemove,
  onTagsChange,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  
  // Get initial headers
  const initialHeaders = useMemo(() => extractHeaders(content), [content]);
  
  // Initialize with all sections collapsed
  const [collapsedSections, setCollapsedSections] = useState<Set<string>>(() => {
    const paths = new Set<string>();
    
    const addHeaderPaths = (header: Header, path: string = '') => {
      const headerPath = path ? `${path}/${header.text}` : header.text;
      if (header.children.length > 0) {
        paths.add(headerPath);
        header.children.forEach(child => addHeaderPaths(child, headerPath));
      }
    };
    
    initialHeaders.forEach(header => addHeaderPaths(header));
    return paths;
  });

  const toggleSection = useCallback((headerPath: string) => {
    setCollapsedSections(prev => {
      const next = new Set(prev);
      if (next.has(headerPath)) {
        next.delete(headerPath);
      } else {
        next.add(headerPath);
      }
      return next;
    });
  }, []);

  const headers = useMemo(() => extractHeaders(content), [content]);

  const renderHeader = useCallback((header: Header, path: string = '') => {
    const headerPath = path ? `${path}/${header.text}` : header.text;
    const isCollapsed = collapsedSections.has(headerPath);
    const hasChildren = header.children.length > 0;

    return (
      <li key={headerPath}>
        <div
          className="flex items-center group"
          style={{ paddingLeft: `${(header.level - 1) * 12}px` }}
        >
          {hasChildren && (
            <button
              onClick={() => toggleSection(headerPath)}
              className="p-1 text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
            >
              {isCollapsed ? (
                <ChevronRight size={12} />
              ) : (
                <ChevronDown size={12} />
              )}
            </button>
          )}
          <button
            onClick={() => onHeaderClick(header.index)}
            className="flex-1 flex items-center py-1 px-2 text-sm text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white rounded group"
          >
            <span className="truncate">{header.text}</span>
          </button>
        </div>
        {hasChildren && !isCollapsed && (
          <ul className="ml-2">
            {header.children.map(child =>
              renderHeader(child, headerPath)
            )}
          </ul>
        )}
      </li>
    );
  }, [onHeaderClick, collapsedSections, toggleSection]);

  if (headers.length === 0) {
    return null;
  }

  if (isCollapsed) {
    return (
      <button
        onClick={onToggleCollapse}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="h-full w-full flex items-center justify-center text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        title="Show table of contents"
      >
        <ChevronRight size={16} />
      </button>
    );
  }

  return (
    <div className="h-full flex flex-col">
      {/* Fixed Header */}
      <div className="flex-none border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between p-4">
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Contents</h3>
          <button
            onClick={onToggleCollapse}
            className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            title="Hide table of contents"
          >
            <ChevronLeft size={16} />
          </button>
        </div>
      </div>

      {/* Scrollable Content */}
      <nav className="flex-1 overflow-y-auto">
        <ul className="p-4 space-y-1">
          {headers.map(header => renderHeader(header))}
        </ul>
      </nav>
    </div>
  );
};
