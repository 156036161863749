import { Note } from '../../types';

export type Section = 'backlinks' | 'current' | 'references' | 'tags';

export interface NodeData {
  noteId: string;
  label: string;
  tags?: string[];
  section: Section;
  created_at?: string;
  last_modified?: string;
}

export interface GraphProps {
  currentNote: Note;
  allNotes: Note[];
  onNodeClick?: (noteId: string) => void;
  onUpdateNote?: (note: Note) => Promise<void>;
}

export const SECTION_WIDTH = 300;
export const VERTICAL_SPACING = 100;

export const nodeColors: Record<Section, string> = {
  current: '#3B82F6', // blue-500
  references: '#10B981', // emerald-500
  tags: '#F59E0B', // amber-500
  backlinks: '#8B5CF6', // violet-500
};
