import React, { useState, useRef, useCallback, useEffect } from 'react';
import { X } from 'react-feather';

interface TagEditorProps {
  tags: string[];
  onAdd: (tag: string) => void;
  onRemove: (tag: string) => void;
  onChange?: (tags: string[]) => void;
}

export const TagEditor: React.FC<TagEditorProps> = ({ 
  tags, 
  onAdd, 
  onRemove, 
  onChange 
}) => {
  const [input, setInput] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [localTags, setLocalTags] = useState(tags);
  const inputRef = useRef<HTMLInputElement>(null);

  // Sync with external tag changes
  useEffect(() => {
    setLocalTags(tags);
  }, [tags]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      if (input.trim()) {
        const normalizedTag = input.trim().replace(/^#/, '');
        if (normalizedTag && !localTags.includes(normalizedTag)) {
          onAdd(normalizedTag);
          if (onChange) {
            onChange([...tags, normalizedTag]);
          }
          setInput('');
        }
      }
    } else if (e.key === 'Backspace' && !input && localTags.length > 0) {
      onRemove(localTags[localTags.length - 1]);
      if (onChange) {
        onChange(tags.slice(0, -1));
      }
    }
  }, [input, localTags, tags, onAdd, onRemove, onChange]);

  const handleRemoveTag = useCallback((tagToRemove: string) => {
    onRemove(tagToRemove);
    if (onChange) {
      onChange(tags.filter(t => t !== tagToRemove));
    }
    inputRef.current?.focus();
  }, [tags, onRemove, onChange]);

  return (
    <div 
      className={`flex items-center w-full transition-colors border-b
        ${isFocused ? 'border-blue-500/30' : 'border-transparent hover:border-gray-200 dark:hover:border-gray-700'}
        ${localTags.length === 0 && !isFocused ? 'opacity-50 hover:opacity-100' : ''}`}
    >
      <div className="flex flex-wrap items-center gap-1.5 py-1">
        {localTags.map(tag => (
          <span 
            key={tag} 
            className="inline-flex items-center px-1.5 py-0.5 text-xs font-medium text-blue-600 dark:text-blue-400"
          >
            #{tag}
            <button 
              onClick={() => handleRemoveTag(tag)}
              className="ml-1 hover:text-red-500 transition-colors opacity-0 group-hover:opacity-100 focus:opacity-100"
              title="Remove tag"
            >
              <X size={12} />
            </button>
          </span>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder={localTags.length === 0 ? "Add tags..." : ""}
          className="flex-grow bg-transparent outline-none text-xs text-gray-700 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-600 min-w-[80px] py-0.5"
        />
      </div>
    </div>
  );
};
