import React, { useCallback, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { Trash2, Code as CodeIcon } from 'react-feather';

interface ParagraphProps {
  content: string;
  index: number;
  isEditing: boolean;
  onEdit: (index: number, content: string) => void;
  onBlur: () => void;
  onClick: (index: number) => void;
  onKeyDown: (e: React.KeyboardEvent, index: number) => void;
  renderCodeBlock: (content: string, language: string, lineNumber: number) => JSX.Element;
  onDelete: (index: number) => void;
  totalParagraphs: number;
  setEditingParagraphIndex: (index: number | null) => void;
}

interface CodeComponentProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
}

const CodeComponent = React.memo(function CodeComponent({ 
  inline, 
  className, 
  children, 
  ...props 
}: CodeComponentProps & React.HTMLAttributes<HTMLElement>): JSX.Element {
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1] : '';
  const codeContent = String(children).replace(/\n$/, '');

  if (inline) {
    return (
      <code className="px-1.5 py-0.5 rounded-md bg-white dark:bg-gray-800 font-mono text-sm" {...props}>
        {children}
      </code>
    );
  }

  return (
    <div className="relative">
      <pre className="p-4 bg-white dark:bg-gray-800 rounded-md overflow-x-auto">
        <code className="block font-mono text-sm">
          {codeContent}
        </code>
      </pre>
    </div>
  );
});

export const Paragraph: React.FC<ParagraphProps> = ({
  content,
  index,
  isEditing,
  onEdit,
  onBlur,
  onClick,
  onKeyDown,
  renderCodeBlock,
  onDelete,
  totalParagraphs,
  setEditingParagraphIndex,
}) => {
  const isCodeBlock = content.trim().startsWith('```');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const paragraphRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      adjustTextareaHeight();
    }
  }, [isEditing]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const textarea = e.target as HTMLTextAreaElement;
    const { selectionStart, value } = textarea;
    const lines = value.split('\n');
    const currentLineIndex = value.slice(0, selectionStart).split('\n').length - 1;
    const currentLine = lines[currentLineIndex];
    
    // Handle code block shortcuts
    if (e.metaKey) {
      if (e.key === ',') { // Cmd + , for Python
        e.preventDefault();
        const beforeCursor = value.slice(0, selectionStart);
        const afterCursor = value.slice(selectionStart);
        const newContent = beforeCursor + '```python\n\n```' + afterCursor;
        onEdit(index, newContent);
        if (textareaRef.current) {
          // Position cursor after the language declaration
          const newPosition = selectionStart + 9; // length of ```python\n
          setTimeout(() => {
            textareaRef.current!.selectionStart = newPosition;
            textareaRef.current!.selectionEnd = newPosition;
          }, 0);
        }
        return;
      } else if (e.key === '.') { // Cmd + . for C
        e.preventDefault();
        const beforeCursor = value.slice(0, selectionStart);
        const afterCursor = value.slice(selectionStart);
        const newContent = beforeCursor + '```c\n\n```' + afterCursor;
        onEdit(index, newContent);
        if (textareaRef.current) {
          const newPosition = selectionStart + 4; // length of ```c\n
          setTimeout(() => {
            textareaRef.current!.selectionStart = newPosition;
            textareaRef.current!.selectionEnd = newPosition;
          }, 0);
        }
        return;
      } else if (e.key === '/') { // Cmd + / for C++
        e.preventDefault();
        const beforeCursor = value.slice(0, selectionStart);
        const afterCursor = value.slice(selectionStart);
        const newContent = beforeCursor + '```cpp\n\n```' + afterCursor;
        onEdit(index, newContent);
        if (textareaRef.current) {
          const newPosition = selectionStart + 6; // length of ```cpp\n
          setTimeout(() => {
            textareaRef.current!.selectionStart = newPosition;
            textareaRef.current!.selectionEnd = newPosition;
          }, 0);
        }
        return;
      }
    }
    
    // Handle code block creation
    if (e.key === 'Enter' && !e.shiftKey && currentLine.trim() === '```') {
      e.preventDefault();
      onKeyDown(e, index); // Create a new paragraph instead of special handling
      return;
    }

    // Handle Enter within code blocks
    if (isCodeBlock && e.key === 'Enter' && !e.shiftKey) {
      const beforeCursor = value.slice(0, selectionStart);
      const afterCursor = value.slice(selectionStart);
      
      // Get the indentation of the current line
      const currentLine = beforeCursor.split('\n').pop() || '';
      const indentation = currentLine.match(/^\s*/)?.[0] || '';
      
      // Insert newline with same indentation
      e.preventDefault();
      const newContent = beforeCursor + '\n' + indentation + afterCursor;
      onEdit(index, newContent);
      
      // Set cursor position after indentation
      setTimeout(() => {
        if (textareaRef.current) {
          const newPosition = selectionStart + 1 + indentation.length;
          textareaRef.current.selectionStart = newPosition;
          textareaRef.current.selectionEnd = newPosition;
        }
      }, 0);
      return;
    }

    if (e.key === 'ArrowUp' && textareaRef.current?.selectionStart === 0) {
      e.preventDefault();
      if (index > 0) {
        setEditingParagraphIndex(index - 1);
      }
    } else if (e.key === 'ArrowDown' && 
        textareaRef.current && 
        textareaRef.current.selectionStart === textareaRef.current.value.length) {
      e.preventDefault();
      if (index < totalParagraphs - 1) {
        setEditingParagraphIndex(index + 1);
      }
    } else if (e.key === 'Enter' && !e.shiftKey && !isCodeBlock) {
      onKeyDown(e, index);
    } else if (e.key === 'Backspace' && 
        textareaRef.current?.selectionStart === 0 && 
        textareaRef.current?.selectionEnd === 0) {
      e.preventDefault();
      if (index > 0) {
        setEditingParagraphIndex(index - 1);
        onDelete(index);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onEdit(index, e.target.value);
    adjustTextareaHeight();
  };

  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.closest('.code-block-controls') || target.closest('.code-block-output')) {
      return;
    }
    onClick(index);
  };

  const renderParagraphToolbar = useCallback(() => {
    return (
      <div className="absolute right-2 top-2 opacity-0 group-hover:opacity-100 transition-opacity flex space-x-2 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-md shadow-sm p-1">
        <button
          onClick={() => onDelete(index)}
          className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded text-red-500"
          title="Delete paragraph"
        >
          <Trash2 size={14} />
        </button>
      </div>
    );
  }, [content, index, onDelete]);

  const renderContent = () => {
    if (isCodeBlock) {
      const match = content.match(/^```(\w+)\r?\n([\s\S]*?)```$/);
      if (match) {
        const [_, language, code] = match;
        return renderCodeBlock(code.trim(), language.trim(), index);
      }
    }

    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={markdownComponents}
        className="prose dark:prose-invert max-w-none"
      >
        {content}
      </ReactMarkdown>
    );
  };

  const markdownComponents: Components = {
    code: ({ node, inline, className, children, ...props }: any) => {
      if (inline) {
        return (
          <code className="px-1.5 py-0.5 rounded-md bg-white dark:bg-gray-800 font-mono text-sm" {...props}>
            {children}
          </code>
        );
      }

      // Only handle non-fenced code blocks here
      const codeContent = String(children).replace(/\n$/, '');
      return (
        <pre className="p-4 bg-white dark:bg-gray-800 rounded-md overflow-x-auto">
          <code className="block font-mono text-sm">{codeContent}</code>
        </pre>
      );
    }
  };

  if (isEditing) {
    return (
      <div
        ref={paragraphRef}
        className={`group relative p-2 transition-colors border-b ${
          isEditing ? 'border-blue-500/30' : 'border-transparent hover:border-gray-200 dark:hover:border-gray-700'
        }`}
        onClick={handleClick}
      >
        <div className="relative">
          <textarea
            ref={textareaRef}
            value={content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
            className="w-full resize-none bg-transparent outline-none text-gray-900 dark:text-white"
            rows={1}
          />
          {renderParagraphToolbar()}
        </div>
      </div>
    );
  }

  return (
    <div
      ref={paragraphRef}
      className={`group relative p-2 transition-colors border-b ${
        isEditing ? 'border-blue-500/30' : 'border-transparent hover:border-gray-200 dark:hover:border-gray-700'
      }`}
      onClick={handleClick}
    >
      {renderContent()}
      {renderParagraphToolbar()}
    </div>
  );
};
