import React, { useState, useEffect, useCallback } from 'react';
import { AIService } from '../../services/AIService';
import { NoteAssistantService } from '../../services/NoteAssistantService';
import { Note } from '../../types';

interface AIAssistantProps {
  currentNote: Note;
  allNotes: Note[];
  onInsertContent: (content: string) => void;
  onAddTags: (tags: string[]) => void;
  onCreateNote?: (note: Note) => void;
  onUpdateNote?: (note: Note) => void;
}

export const AIAssistant: React.FC<AIAssistantProps> = ({
  currentNote,
  allNotes,
  onInsertContent,
  onAddTags,
  onCreateNote,
  onUpdateNote,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState<'generate' | 'enhance' | 'connect'>('generate');
  const [connections, setConnections] = useState<Array<{ noteId: string; reason: string }>>([]);
  const [sectionTitle, setSectionTitle] = useState('');
  const [newNoteTopic, setNewNoteTopic] = useState('');
  const [streamingContent, setStreamingContent] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const handleFindConnections = useCallback(async () => {
    if (!currentNote?.id) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.suggestConnections(currentNote.id);
      if (result.error) {
        setError(result.error);
      } else if (result.connections) {
        setConnections(result.connections);
      }
    } catch (error) {
      console.error('Error finding connections:', error);
      setError('Failed to find connections');
    }
    setIsLoading(false);
  }, [currentNote]);

  useEffect(() => {
    if (currentNote?.content && activeTab === 'connect') {
      handleFindConnections();
    }
  }, [currentNote, activeTab, handleFindConnections]);

  const loadSuggestions = async () => {
    setIsLoading(true);
    try {
      const newSuggestions = await AIService.generateSuggestions(currentNote, allNotes);
      setSuggestions(newSuggestions);
    } catch (error) {
      console.error('Error loading suggestions:', error);
    }
    setIsLoading(false);
  };

  const handleGenerateContent = async () => {
    if (!prompt.trim()) return;
    
    setIsLoading(true);
    setError('');
    setStreamingContent('');
    setIsStreaming(true);

    try {
      const { content, error } = await AIService.generateContent(prompt, (token) => {
        setStreamingContent(prev => prev + token);
      });

      if (error) {
        setError(error);
      } else if (content) {
        onInsertContent(content);
        setPrompt('');
        setIsOpen(false);
      }
    } catch (err) {
      setError('Failed to generate content');
    }

    setIsLoading(false);
    setIsStreaming(false);
  };

  const handleGenerateTags = async () => {
    setIsLoading(true);
    try {
      const tags = await AIService.generateTags(currentNote.content);
      onAddTags(tags);
    } catch (error) {
      console.error('Error generating tags:', error);
    }
    setIsLoading(false);
  };

  const handleCreateNoteFromTopic = async () => {
    if (!newNoteTopic.trim()) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.createNoteFromTopic(newNoteTopic);
      if (result.error) {
        setError(result.error);
      } else if (result.note && onCreateNote) {
        onCreateNote(result.note);
        setNewNoteTopic('');
      }
    } catch (err) {
      setError('Failed to create note');
    }
    setIsLoading(false);
  };

  const handleExpandSection = async () => {
    if (!sectionTitle.trim() || !currentNote?.id) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.expandSection(currentNote.id, sectionTitle);
      if (result.error) {
        setError(result.error);
      } else if (result.content) {
        onInsertContent(result.content);
        setSectionTitle('');
      }
    } catch (err) {
      setError('Failed to expand section');
    }
    setIsLoading(false);
  };

  const handleGenerateTableOfContents = async () => {
    if (!currentNote?.content) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.generateTableOfContents(currentNote.content);
      if (result.error) {
        setError(result.error);
      } else if (result.toc) {
        onInsertContent(result.toc);
      }
    } catch (err) {
      setError('Failed to generate table of contents');
    }
    setIsLoading(false);
  };

  const handleSuggestReorganization = async () => {
    if (!currentNote?.id) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.suggestReorganization(currentNote.id);
      if (result.error) {
        setError(result.error);
      } else if (result.suggestions) {
        onInsertContent(result.suggestions);
      }
    } catch (err) {
      setError('Failed to suggest reorganization');
    }
    setIsLoading(false);
  };

  const handleCreateLinkedNote = async (topic: string) => {
    if (!currentNote?.id) return;
    setIsLoading(true);
    try {
      const result = await NoteAssistantService.createLinkedNote(currentNote.id, topic);
      if (result.error) {
        setError(result.error);
      } else if (result.note && onCreateNote) {
        onCreateNote(result.note);
      }
    } catch (err) {
      setError('Failed to create linked note');
    }
    setIsLoading(false);
  };

  return (
    <div className="fixed bottom-4 right-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 shadow-lg"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute bottom-16 right-0 w-96 bg-white dark:bg-gray-800 rounded-lg shadow-xl p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">AI Assistant</h3>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              ×
            </button>
          </div>

          {/* Tabs */}
          <div className="flex space-x-2 mb-4">
            <button
              onClick={() => setActiveTab('generate')}
              className={`px-3 py-1 rounded ${
                activeTab === 'generate'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 dark:bg-gray-700'
              }`}
            >
              Generate
            </button>
            <button
              onClick={() => setActiveTab('enhance')}
              className={`px-3 py-1 rounded ${
                activeTab === 'enhance'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 dark:bg-gray-700'
              }`}
            >
              Enhance
            </button>
            <button
              onClick={() => setActiveTab('connect')}
              className={`px-3 py-1 rounded ${
                activeTab === 'connect'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 dark:bg-gray-700'
              }`}
            >
              Connect
            </button>
          </div>

          <div className="space-y-4">
            {activeTab === 'generate' && (
              <>
                {/* Content Generation */}
                <div>
                  <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Enter a prompt for content generation..."
                    className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    rows={3}
                  />
                  <button
                    onClick={handleGenerateContent}
                    disabled={isLoading}
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                  >
                    {isLoading ? 'Generating...' : 'Generate Content'}
                  </button>

                  {isStreaming && streamingContent && (
                    <div className="mt-4 p-3 bg-gray-50 dark:bg-gray-700 rounded">
                      <div className="font-medium text-gray-700 dark:text-gray-300 mb-2">Preview:</div>
                      <div className="whitespace-pre-wrap">{streamingContent}</div>
                    </div>
                  )}
                </div>

                {/* New Note from Topic */}
                <div>
                  <input
                    type="text"
                    value={newNoteTopic}
                    onChange={(e) => setNewNoteTopic(e.target.value)}
                    placeholder="Enter topic for new note..."
                    className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                  <button
                    onClick={handleCreateNoteFromTopic}
                    disabled={isLoading}
                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50"
                  >
                    Create New Note
                  </button>
                </div>
              </>
            )}

            {activeTab === 'enhance' && (
              <>
                {/* Section Expansion */}
                <div>
                  <input
                    type="text"
                    value={sectionTitle}
                    onChange={(e) => setSectionTitle(e.target.value)}
                    placeholder="Enter section title to expand..."
                    className="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                  <button
                    onClick={handleExpandSection}
                    disabled={isLoading}
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                  >
                    Expand Section
                  </button>
                </div>

                {/* Quick Actions */}
                <div className="flex flex-col space-y-2">
                  <button
                    onClick={handleGenerateTags}
                    disabled={isLoading}
                    className="px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    Generate Tags
                  </button>
                  <button
                    onClick={handleGenerateTableOfContents}
                    disabled={isLoading}
                    className="px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    Generate Table of Contents
                  </button>
                  <button
                    onClick={handleSuggestReorganization}
                    disabled={isLoading}
                    className="px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    Suggest Reorganization
                  </button>
                </div>
              </>
            )}

            {activeTab === 'connect' && (
              <>
                <button
                  onClick={handleFindConnections}
                  disabled={isLoading}
                  className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                >
                  Find Connected Notes
                </button>

                {/* Connections List */}
                {connections.length > 0 && (
                  <div className="mt-4">
                    <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Connected Notes
                    </h4>
                    <ul className="space-y-2">
                      {connections.map((connection, index) => {
                        const connectedNote = allNotes.find(
                          (note) => note.id === connection.noteId
                        );
                        return (
                          <li
                            key={index}
                            className="p-2 bg-gray-50 dark:bg-gray-700 rounded"
                          >
                            <div className="font-medium">
                              {connectedNote?.title || 'Untitled Note'}
                            </div>
                            <div className="text-sm text-gray-600 dark:text-gray-400">
                              {connection.reason}
                            </div>
                            <button
                              onClick={() =>
                                handleCreateLinkedNote(
                                  connectedNote?.title || 'Connected Topic'
                                )
                              }
                              className="mt-2 px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
                            >
                              Create Linked Note
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </>
            )}

            {/* Suggestions */}
            {activeTab === 'generate' && suggestions.length > 0 && (
              <div>
                <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Suggestions
                  <button
                    onClick={loadSuggestions}
                    disabled={isLoading}
                    className="ml-2 px-2 py-1 text-sm bg-gray-100 dark:bg-gray-700 rounded hover:bg-gray-200 dark:hover:bg-gray-600"
                  >
                    Refresh
                  </button>
                </h4>
                <ul className="space-y-2">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 bg-gray-50 dark:bg-gray-700 rounded cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
                      onClick={() => onInsertContent(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
