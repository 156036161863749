import React, { useState } from 'react';
import { Task, TaskStatus, taskStatusColors, TaskPriority } from '../../types/task';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import TaskCard from './TaskCard';
import TaskEditModal from './TaskEditModal';

interface TaskListProps {
  tasks: Task[];
  onEditTask: (task: Task) => void;
  onDeleteTask: (taskId: string) => void;
  onCreateTask: (task: Task) => void;
  onUpdateTask: (taskId: string, updates: Partial<Task>) => void;
  filter: string;
  onFilterChange: (filter: string) => void;
}

const TaskList = ({
  tasks,
  onEditTask,
  onDeleteTask,
  onCreateTask,
  onUpdateTask,
  filter,
  onFilterChange,
}: TaskListProps) => {
  const [editingTask, setEditingTask] = useState<Task | null>(null);

  const filteredTasks = tasks.filter(
    (task) =>
      task.title.toLowerCase().includes(filter.toLowerCase()) ||
      task.description.toLowerCase().includes(filter.toLowerCase()) ||
      task.tags.some((tag) => tag.toLowerCase().includes(filter.toLowerCase()))
  );

  const tasksByStatus = {
    [TaskStatus.TODO]: filteredTasks.filter((task) => task.status === TaskStatus.TODO),
    [TaskStatus.IN_PROGRESS]: filteredTasks.filter(
      (task) => task.status === TaskStatus.IN_PROGRESS
    ),
    [TaskStatus.COMPLETED]: filteredTasks.filter((task) => task.status === TaskStatus.COMPLETED),
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const task = tasks.find((t) => t.id === draggableId);
    if (!task) return;

    onUpdateTask(draggableId, {
      status: destination.droppableId as TaskStatus,
      last_modified: new Date().toISOString()
    });
  };

  const handleTaskEdit = (task: Task) => {
    // If the task has the same ID as the one we're editing, it's a status update
    if (task.id === editingTask?.id) {
      onUpdateTask(task.id, {
        ...task,
        last_modified: new Date().toISOString()
      });
    } else {
      // Only open the edit modal if it's not already open
      if (!editingTask) {
        setEditingTask(task);
      }
    }
  };

  const handleModalSave = async (task: Task) => {
    try {
      if (task.id) {
        await onUpdateTask(task.id, task);
      } else {
        await onCreateTask(task);
      }
    } finally {
      setEditingTask(null); // Always close the modal after save attempt
    }
  };

  const handleModalClose = () => {
    setEditingTask(null);
  };

  const handleCreateTask = () => {
    const newTask: Task = {
      id: '', // Will be set by backend
      title: '',
      description: '',
      status: TaskStatus.TODO,
      priority: TaskPriority.MEDIUM,
      dueDate: new Date().toISOString(),
      tags: [],
      linkedNoteIds: [],
      created_at: new Date().toISOString(),
      last_modified: new Date().toISOString()
    };
    setEditingTask(newTask);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-4 px-4">
        <div className="relative flex-1 max-w-xs">
          <input
            type="text"
            placeholder="Search tasks..."
            value={filter}
            onChange={(e) => onFilterChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <button
          onClick={handleCreateTask}
          className="ml-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex items-center"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          New Task
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-4 flex-1 min-h-0">
          {Object.entries(tasksByStatus).map(([status, statusTasks]) => (
            <div
              key={status}
              className="flex flex-col bg-gray-100 rounded-lg p-4"
            >
              <h3 className="text-lg font-semibold mb-4 flex items-center">
                <span
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: taskStatusColors[status as TaskStatus] }}
                />
                {status}
                <span className="ml-2 text-sm text-gray-500">
                  ({statusTasks.length})
                </span>
              </h3>
              <Droppable droppableId={status}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="flex-1 overflow-y-auto"
                  >
                    {statusTasks.map((task, index) => (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TaskCard
                              task={task}
                              onEdit={handleTaskEdit}
                              onDelete={onDeleteTask}
                              className="mb-3"
                              index={index}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>

      {editingTask && (
        <TaskEditModal
          task={editingTask}
          onSave={handleModalSave}
          onClose={() => setEditingTask(null)}
        />
      )}
    </div>
  );
};

export default TaskList;
