import React, { useState, useEffect, useCallback } from 'react';
import Notebook from './components/Notebook/Notebook';
import { Note } from './types';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';
import { NoteGraph } from './components/Graph';
import { SearchModal } from './components/Search/SearchModal';
import { Header } from './components/Header/Header'; // Import the correct Header component
import { Settings } from './components/Settings/Settings';
import { ThemeProvider } from './context/ThemeContext';
import { TaskView } from './components/Tasks/TaskView';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import axiosInstance from './services/axiosConfig'; // Import axios
import { isAxiosError } from 'axios';
import { SplashScreen } from './components/SplashScreen/SplashScreen';
import LandingPage from './pages/LandingPage';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

function AppContent() {
  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(() => {
    const saved = localStorage.getItem('selectedNoteId');
    return saved || null;
  });
  const [showSearch, setShowSearch] = useState(false);
  const [showGraph, setShowGraph] = useState<boolean>(() => {
    const savedGraphState = localStorage.getItem('showGraph');
    return savedGraphState === 'true';
  });
  const [showSettings, setShowSettings] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(false);

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      setShowSplashScreen(!localStorage.getItem('hasSeenSplashScreen'));
    }
  }, [isAuthenticated]);

  // Get the server port from the window object (injected by Electron)
  const serverPort = (window as any).SERVER_PORT || 3001;
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || `http://localhost:${serverPort}/api`;

  const fetchNotes = useCallback(async () => {
    try {
      console.log('Fetching notes from:', `${apiBaseUrl}/notes`);
      const response = await axiosInstance.get(`${apiBaseUrl}/notes`);
      console.log('Response status:', response.status);
      const data = response.data;
      console.log('Received data:', data);
      // Handle both array and object responses
      const notesList = Array.isArray(data) ? data : data.notes || [];
      console.log('Parsed notes list:', notesList);
      setNotes(notesList);
      
      // If no note is selected, select the first one
      if (!selectedNoteId && notesList.length > 0) {
        console.log('No note selected, selecting first note:', notesList[0].id);
        setSelectedNoteId(notesList[0].id);
      } else if (selectedNoteId) {
        // Verify the selected note still exists
        const noteExists = notesList.some((note: Note) => note.id === selectedNoteId);
        console.log('Selected note exists:', noteExists);
        if (!noteExists && notesList.length > 0) {
          console.log('Selected note not found, selecting first note:', notesList[0].id);
          setSelectedNoteId(notesList[0].id);
        } else if (!noteExists) {
          console.log('Selected note not found and no notes available');
          setSelectedNoteId(null);
        }
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  }, [apiBaseUrl, selectedNoteId]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotes();
    }
  }, [isAuthenticated, fetchNotes]);

  // Save selected note ID to localStorage
  useEffect(() => {
    if (selectedNoteId) {
      localStorage.setItem('selectedNoteId', selectedNoteId);
    } else {
      localStorage.removeItem('selectedNoteId');
    }
  }, [selectedNoteId]);

  // Save graph state to localStorage
  useEffect(() => {
    localStorage.setItem('showGraph', showGraph.toString());
  }, [showGraph]);

  useEffect(() => {
    if (isAuthenticated) {
      setShowSplashScreen(!localStorage.getItem('hasSeenSplashScreen'));
    }
  }, [isAuthenticated]);

  const handleCreateNote = async () => {
    try {
      const newNote = {
        content: '',
        created_at: new Date().toISOString(),
        last_modified: new Date().toISOString(),
        tags: [],
      };

      const response = await axiosInstance.post(`${apiBaseUrl}/notes`, newNote);
      const createdNote = response.data;
      
      // Add the new note with server ID to the local state
      setNotes(prevNotes => [...prevNotes, createdNote]);
      setSelectedNoteId(createdNote.id);
      setShowSearch(false); // Close search if open
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };

  const handleDeleteNote = async (noteId: string) => {
    console.log(`[Frontend] Attempting to delete note with id: ${noteId}`);
    try {
      console.log(`[Frontend] Sending DELETE request to ${apiBaseUrl}/notes/${noteId}`);
      await axiosInstance.delete(`${apiBaseUrl}/notes/${noteId}`);
      console.log(`[Frontend] DELETE request successful for note ${noteId}`);
      
      console.log(`[Frontend] Updating local state to remove note ${noteId}`);
      setNotes(prevNotes => {
        const updatedNotes = prevNotes.filter(note => note.id !== noteId);
        console.log(`[Frontend] Notes after deletion:`, updatedNotes);
        return updatedNotes;
      });

      if (selectedNoteId === noteId) {
        console.log(`[Frontend] Deleted note was selected, updating selection`);
        const remainingNotes = notes.filter(note => note.id !== noteId);
        const newSelectedId = remainingNotes.length > 0 ? remainingNotes[0].id : null;
        console.log(`[Frontend] New selected note id: ${newSelectedId}`);
        setSelectedNoteId(newSelectedId);
      }
    } catch (error) {
      console.error('[Frontend] Error deleting note:', error);
      if (isAxiosError(error)) {
        console.error('[Frontend] Axios error details:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
      }
    }
  };

  const handleUpdateNote = async (updatedNote: Note) => {
    try {
      const noteToUpdate = {
        content: updatedNote.content,
        last_modified: new Date().toISOString(),
        tags: updatedNote.tags || [],
        code_outputs: updatedNote.code_outputs || {},
        backlinks: updatedNote.backlinks || [],
        references: updatedNote.references || [],
        suggested_links: updatedNote.suggested_links || []
      };

      const response = await axiosInstance.put(`${apiBaseUrl}/notes/${updatedNote.id}`, noteToUpdate);
      const updatedNoteFromServer = response.data;

      setNotes(prevNotes =>
        prevNotes.map(note =>
          note.id === updatedNoteFromServer.id ? updatedNoteFromServer : note
        )
      );
    } catch (error) {
      console.error('Error updating note:', error);
      throw error;
    }
  };

  const handleSelectNote = useCallback((noteId: string) => {
    if (selectedNoteId === noteId) return; // Don't reselect the same note
    
    setSelectedNoteId(noteId);
    // Fetch the latest version of the note
    const selectedNote = notes.find(note => note.id === noteId);
    if (selectedNote) {
      // Force a refresh of the note data
      handleUpdateNote({
        ...selectedNote,
        last_modified: new Date().toISOString()
      });
    }
  }, [notes, selectedNoteId, handleUpdateNote]);

  const handlePanelToggle = (panel: 'search' | 'settings' | 'tasks') => {
    // Close all other panels first
    if (panel !== 'search') setShowSearch(false);
    if (panel !== 'settings') setShowSettings(false);
    if (panel !== 'tasks') setShowTasks(false);

    // Toggle the requested panel
    switch (panel) {
      case 'search':
        setShowSearch(prev => !prev);
        break;
      case 'settings':
        setShowSettings(prev => !prev);
        break;
      case 'tasks':
        setShowTasks(prev => !prev);
        break;
    }
  };

  // Register keyboard shortcuts
  useKeyboardShortcuts({
    onNewNote: handleCreateNote,
    onToggleGraph: () => setShowGraph(!showGraph),
    onSearch: () => handlePanelToggle('search'),
    onToggleTasks: () => handlePanelToggle('tasks')
  });

  const selectedNote = notes.find(note => note.id === selectedNoteId);

  const handleCloseSplashScreen = useCallback(() => {
    setShowSplashScreen(false);
    localStorage.setItem('hasSeenSplashScreen', 'true');
  }, []);

  return (
    <div className="h-screen flex flex-col bg-white dark:bg-gray-900">
      {showSplashScreen && <SplashScreen onClose={handleCloseSplashScreen} />}
      <Routes>
        <Route path="/" element={!isAuthenticated ? <LandingPage /> : <Navigate to="/app" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/app/*"
          element={
            <ProtectedRoute>
              <div className="h-screen flex flex-col">
                <Header
                  onNewNote={handleCreateNote}
                  onToggleGraph={() => setShowGraph(!showGraph)}
                  onToggleSearch={() => handlePanelToggle('search')}
                  onToggleSettings={() => handlePanelToggle('settings')}
                  onToggleTasks={() => handlePanelToggle('tasks')}
                />
                
                <div className="flex-1 flex relative overflow-hidden bg-gray-50">
                  {/* Main Content */}
                  <div className={`w-full transition-transform duration-300 ease-in-out ${
                    showTasks || showSettings || showSearch ? 'translate-x-[-20%]' : 'translate-x-0'
                  }`}>
                    {showGraph ? (
                      <div className="h-[calc(100vh-3.5rem)]">
                        {selectedNote && (
                          <NoteGraph
                            currentNote={selectedNote}
                            allNotes={notes}
                            onNodeClick={handleSelectNote}
                            onUpdateNote={handleUpdateNote}
                          />
                        )}
                      </div>
                    ) : (
                      selectedNote && (
                        <Notebook
                          note={selectedNote}
                          notes={notes}
                          backlinks={notes.filter(n => n.references?.includes(selectedNote.id))}
                          onUpdate={handleUpdateNote}
                          onDelete={() => handleDeleteNote(selectedNote.id)}
                          onNoteClick={handleSelectNote}
                        />
                      )
                    )}
                  </div>

                  {/* Side Panels Container */}
                  <div className={`fixed top-[3.5rem] bottom-0 right-0 w-[40%] bg-gray-50 transition-transform duration-300 ease-in-out ${
                    showTasks || showSettings || showSearch ? 'translate-x-0' : 'translate-x-full'
                  }`}>
                    {/* Task Panel */}
                    <div className={`absolute inset-0 transition-transform duration-300 ease-in-out bg-gray-50 ${
                      showTasks ? 'translate-x-0' : 'translate-x-full'
                    }`}>
                      <TaskView show={showTasks} />
                    </div>

                    {/* Settings Panel */}
                    <div className={`absolute inset-0 transition-transform duration-300 ease-in-out bg-gray-50 ${
                      showSettings ? 'translate-x-0' : 'translate-x-full'
                    }`}>
                      <Settings show={showSettings} />
                    </div>

                    {/* Search Panel */}
                    <div className={`absolute inset-0 transition-transform duration-300 ease-in-out bg-gray-50 ${
                      showSearch ? 'translate-x-0' : 'translate-x-full'
                    }`}>
                      <SearchModal
                        show={showSearch}
                        onClose={() => handlePanelToggle('search')}
                        notes={notes}
                        onNoteSelect={(noteId) => {
                          setSelectedNoteId(noteId);
                          handlePanelToggle('search');
                        }}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
