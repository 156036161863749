import React from 'react';
import { Note } from '../../types';

interface SearchResultProps {
  note: Note;
  isSelected: boolean;
  onClick: () => void;
  showBacklinks: boolean;
}

export const SearchResult: React.FC<SearchResultProps> = ({
  note,
  isSelected,
  onClick,
  showBacklinks,
}) => {
  const truncateContent = (content: string, maxLength: number = 150) => {
    if (content.length <= maxLength) return content;
    return content.substring(0, maxLength) + '...';
  };

  return (
    <div
      className={`p-4 cursor-pointer rounded-lg transition-colors ${
        isSelected
          ? 'bg-blue-50 dark:bg-blue-900'
          : 'hover:bg-gray-50 dark:hover:bg-gray-800'
      }`}
      onClick={onClick}
    >
      <div className="space-y-2">
        <div className="flex justify-between items-start">
          <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">
            {note.title || 'Untitled Note'}
          </h3>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {new Date(note.last_modified).toLocaleDateString()}
          </span>
        </div>
        <p className="text-gray-600 dark:text-gray-300">
          {truncateContent(note.content)}
        </p>
        {showBacklinks && note.backlinks && note.backlinks.length > 0 && (
          <div className="mt-2">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Referenced by: {note.backlinks.length} note
              {note.backlinks.length !== 1 ? 's' : ''}
            </p>
            <div className="flex flex-wrap gap-2 mt-1">
              {note.backlinks.slice(0, 3).map((backlink, index) => (
                <span
                  key={index}
                  className="px-2 py-1 text-xs rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300"
                >
                  {backlink.noteId}
                </span>
              ))}
              {note.backlinks.length > 3 && (
                <span className="px-2 py-1 text-xs rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300">
                  +{note.backlinks.length - 3} more
                </span>
              )}
            </div>
          </div>
        )}
        {note.tags && note.tags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {note.tags.map((tag, index) => (
              <span
                key={index}
                className="px-2 py-1 text-xs rounded-full bg-blue-100 dark:bg-blue-800 text-blue-600 dark:text-blue-200"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
