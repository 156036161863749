import { Node, Edge } from 'reactflow';
import { Note } from '../../types/Note';
import { NodeData, Section, SECTION_WIDTH, VERTICAL_SPACING } from './types';

export const getSectionX = (section: Section): number => {
  switch (section) {
    case 'backlinks':
      return 0;
    case 'current':
      return SECTION_WIDTH;
    case 'references':
      return SECTION_WIDTH * 2;
    case 'tags':
      return SECTION_WIDTH * 3;
    default:
      return 0;
  }
};

export const createNode = (
  note: Note,
  section: Section,
  index: number
): Node<NodeData> => ({
  id: `${section}-${note.id}`,
  type: 'noteNode',
  position: {
    x: getSectionX(section),
    y: index * VERTICAL_SPACING,
  },
  data: {
    noteId: note.id,
    label: note.content,
    tags: note.tags,
    section,
    created_at: note.created_at,
    last_modified: note.last_modified,
  },
});

export const createElements = (
  currentNote: Note,
  allNotes: Note[],
  backlinks: Note[],
  references: Note[],
  tagRelatedNotes: Note[]
): { nodes: Node<NodeData>[]; edges: Edge[] } => {
  const nodes: Node<NodeData>[] = [];
  const edges: Edge[] = [];

  // Add backlinks
  backlinks.forEach((note, index) => {
    nodes.push(createNode(note, 'backlinks', index));
    edges.push({
      id: `backlink-${note.id}-to-${currentNote.id}`,
      source: `backlinks-${note.id}`,
      target: `current-${currentNote.id}`,
      type: 'smoothstep',
    });
  });

  // Add current note
  nodes.push(createNode(currentNote, 'current', 0));

  // Add references
  references.forEach((note, index) => {
    nodes.push(createNode(note, 'references', index));
    edges.push({
      id: `reference-${currentNote.id}-to-${note.id}`,
      source: `current-${currentNote.id}`,
      target: `references-${note.id}`,
      type: 'smoothstep',
    });
  });

  // Add tag-related notes (disconnected)
  tagRelatedNotes.forEach((note, index) => {
    nodes.push(createNode(note, 'tags', index));
  });

  return { nodes, edges };
};
