import React from 'react';
import { Task, TaskStatus, taskPriorityColors, taskStatusColors } from '../../types/task';
import { TrashIcon, PencilIcon, LinkIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';

interface TaskCardProps {
  task: Task;
  index?: number;
  onEdit: (task: Task) => void;
  onDelete: (taskId: string) => void;
  onLink?: (taskId: string) => void;
  className?: string;
}

const TaskCard = ({
  task,
  index,
  onEdit,
  onDelete,
  onLink,
  className = '',
}: TaskCardProps) => {
  const formattedDate = task.dueDate ? format(new Date(task.dueDate), 'MMM d') : '';

  const handleDoubleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const newStatus = (() => {
      switch (task.status) {
        case TaskStatus.TODO:
          return TaskStatus.IN_PROGRESS;
        case TaskStatus.IN_PROGRESS:
          return TaskStatus.COMPLETED;
        case TaskStatus.COMPLETED:
          return TaskStatus.TODO;
        default:
          return TaskStatus.TODO;
      }
    })();

    onEdit({
      ...task,
      status: newStatus,
      last_modified: new Date().toISOString()
    });
  };

  return (
    <div 
      className={`bg-white rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow ${className}`}
      onDoubleClick={handleDoubleClick}
    >
      <div className="p-3">
        <div className="flex justify-between items-start gap-2">
          <h3 className="text-sm font-medium text-gray-900 line-clamp-2">{task.title}</h3>
          <div className="flex gap-0.5">
            {onLink && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onLink(task.id);
                }}
                className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                title="Link to note"
              >
                <LinkIcon className="h-3.5 w-3.5 text-gray-400 hover:text-gray-600" />
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(task);
              }}
              className="p-1 hover:bg-gray-100 rounded-md transition-colors"
              title="Edit task"
            >
              <PencilIcon className="h-3.5 w-3.5 text-gray-400 hover:text-gray-600" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(task.id);
              }}
              className="p-1 hover:bg-gray-100 rounded-md transition-colors"
              title="Delete task"
            >
              <TrashIcon className="h-3.5 w-3.5 text-gray-400 hover:text-gray-600" />
            </button>
          </div>
        </div>

        {task.description && (
          <p className="mt-1 text-sm text-gray-500 line-clamp-2">{task.description}</p>
        )}

        <div className="mt-2 flex items-center gap-2">
          {task.priority && (
            <span
              className="px-2 py-0.5 text-xs rounded-full"
              style={{
                backgroundColor: taskPriorityColors[task.priority],
                color: 'white',
              }}
            >
              {task.priority}
            </span>
          )}
          {task.status && (
            <span
              className="px-2 py-0.5 text-xs rounded-full"
              style={{
                backgroundColor: taskStatusColors[task.status],
                color: 'white',
              }}
            >
              {task.status}
            </span>
          )}
          {formattedDate && (
            <span className="ml-auto text-xs text-gray-400">{formattedDate}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
