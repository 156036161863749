import React from 'react';
import { Note } from '../types/Note';

interface BacklinksProps {
  notebook: { notes: Note[] };
  currentNoteId: string;
  onSelect: (noteId: string) => void;
}

export const Backlinks: React.FC<BacklinksProps> = ({
  notebook,
  currentNoteId,
  onSelect,
}) => {
  const notes = notebook.notes;
  const currentNote = notes.find(note => note.id === currentNoteId);
  if (!currentNote?.backlinks?.length) return null;

  const getTitle = (content: string) => {
    const lines = content.split('\n');
    const titleLine = lines.find(line => line.startsWith('# '));
    return titleLine ? titleLine.substring(2).trim() : 'Untitled Note';
  };

  const getBacklinkNotes = () => {
    return (currentNote.backlinks || [])
      .map(backlink => notes.find(note => note.id === backlink.noteId))
      .filter((note): note is Note => note !== undefined);
  };

  const backlinkNotes = getBacklinkNotes();
  if (backlinkNotes.length === 0) return null;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
      <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        Linked From
      </h3>
      <ul className="space-y-2">
        {backlinkNotes.map(note => (
          <li key={note.id}>
            <button
              onClick={() => onSelect(note.id)}
              className="w-full text-left px-3 py-2 rounded-md text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            >
              {getTitle(note.content)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
