import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Play } from 'react-feather';

interface CodeBlockProps {
  code: string;  // Required
  language: string;  // Required
  onRun?: () => void;
  isExecuting?: boolean;
  output?: string;
  error?: string;
}

export const CodeBlockComponent: React.FC<CodeBlockProps> = ({
  code,
  language,
  onRun,
  isExecuting,
  output,
  error,
}) => {
  const isOutputBlock = !onRun;
  
  return (
    <div className={`relative rounded-lg border ${isOutputBlock ? 'border-gray-100 dark:border-gray-800' : 'border-gray-200 dark:border-gray-700'} bg-white dark:bg-gray-900 overflow-hidden`}>
      <div className="flex items-center justify-between px-4 py-2 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700">
        <span className="text-sm text-gray-600 dark:text-gray-400">
          {isOutputBlock ? 'Output' : language}
        </span>
        {onRun && (
          <button
            onClick={onRun}
            disabled={isExecuting}
            className={`code-run-button flex items-center px-2 py-1 rounded text-sm ${
              isExecuting
                ? 'bg-gray-100 dark:bg-gray-800 text-gray-400 cursor-not-allowed'
                : 'text-gray-800 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-800'
            } transition-colors`}
            title="Run code"
          >
            <Play size={14} className={isExecuting ? 'animate-pulse' : ''} />
          </button>
        )}
      </div>
      <div className={`p-4 bg-white dark:bg-gray-900 ${isOutputBlock ? 'opacity-75' : ''}`}>
        <SyntaxHighlighter
          language={isOutputBlock ? 'plaintext' : language.toLowerCase()}
          style={solarizedlight}
          customStyle={{
            margin: 0,
            padding: '1rem',
            borderRadius: '0.375rem',
            backgroundColor: 'transparent',
            opacity: isOutputBlock ? 0.75 : 1,
          }}
        >
          {code || ''}  {/* Ensure code is never undefined */}
        </SyntaxHighlighter>
        {output && !isOutputBlock && (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800 rounded">
            <pre className="text-sm text-gray-800 dark:text-gray-200 whitespace-pre-wrap">{output}</pre>
          </div>
        )}
        {error && !isOutputBlock && (
          <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 rounded">
            <pre className="text-sm text-red-600 dark:text-red-400 whitespace-pre-wrap">{error}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeBlockComponent;
