import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Calendar, Clock, Tag as TagIcon } from 'react-feather';

interface NotePreviewProps {
  content: string;
  tags?: string[];
  created_at?: string;
  last_modified?: string;
  position: { x: number; y: number };
}

const formatDate = (dateString?: string) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

const NotePreview: React.FC<NotePreviewProps> = ({
  content,
  tags,
  created_at,
  last_modified,
  position,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({
    transform: 'translate(0, 0)',
    opacity: 0,
  });

  useEffect(() => {
    if (previewRef.current) {
      const rect = previewRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Adjust x position if preview would go off screen
      let x = position.x + 20; // 20px offset from cursor
      if (x + rect.width > viewportWidth) {
        x = position.x - rect.width - 20;
      }

      // Adjust y position if preview would go off screen
      let y = position.y - rect.height / 2;
      if (y + rect.height > viewportHeight) {
        y = viewportHeight - rect.height - 20;
      }
      if (y < 20) {
        y = 20;
      }

      // Update position with a fade-in effect
      setStyle({
        transform: `translate(${x}px, ${y}px)`,
        opacity: 1,
      });
    }
  }, [position]);

  return createPortal(
    <div
      ref={previewRef}
      className={`
        fixed top-0 left-0 w-96 bg-white dark:bg-gray-800 rounded-lg shadow-2xl border border-gray-200 dark:border-gray-700 p-4 transition-opacity duration-200
      `}
      style={{
        ...style,
        zIndex: 9999,
        pointerEvents: 'none',
      }}
    >
      {/* Content */}
      <div className="prose prose-sm dark:prose-invert max-h-64 overflow-y-auto mb-4">
        {content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>

      {/* Tags */}
      {tags && tags.length > 0 && (
        <div className="flex flex-wrap gap-1 mb-3">
          {tags.map((tag) => (
            <div
              key={tag}
              className="flex items-center gap-1 px-2 py-0.5 bg-gray-100 dark:bg-gray-700 rounded text-xs text-gray-600 dark:text-gray-300"
            >
              <TagIcon size={10} className="text-gray-400 dark:text-gray-500" />
              {tag}
            </div>
          ))}
        </div>
      )}

      {/* Dates */}
      <div className="flex items-center gap-4 text-xs text-gray-500 dark:text-gray-400 pt-3 border-t border-gray-100 dark:border-gray-700">
        {created_at && (
          <div className="flex items-center gap-1">
            <Calendar size={12} />
            Created {formatDate(created_at)}
          </div>
        )}
        {last_modified && (
          <div className="flex items-center gap-1">
            <Clock size={12} />
            Modified {formatDate(last_modified)}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default NotePreview;
