import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  OnNodesChange,
  applyNodeChanges,
  Node,
  Edge,
  NodeChange,
  ReactFlowProvider,
  useReactFlow,
  Connection,
  Panel,
  addEdge,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { GraphProps, Section, SECTION_WIDTH } from './types';
import { createElements, getSectionX } from './GraphElements';
import NoteNode from './NoteNode';
import { Note } from '../../types';

const nodeTypes = { noteNode: NoteNode };

const NoteGraph: React.FC<GraphProps> = ({
  currentNote,
  allNotes,
  onNodeClick,
  onUpdateNote,
}) => {
  const flowRef = useRef<HTMLDivElement>(null);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [needsUpdate, setNeedsUpdate] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  
  // Memoize filtered notes
  const { backlinks, references, tagRelatedNotes } = useMemo(() => {
    // Get backlinks
    const backlinks = allNotes.filter(note =>
      note.references?.includes(currentNote.id)
    );

    // Get references
    const references = (currentNote.references || [])
      .map(id => allNotes.find(note => note.id === id))
      .filter((note): note is NonNullable<typeof note> => note !== undefined);

    // Get tag-related notes
    const tagRelatedNotes = currentNote.tags
      ? allNotes.filter(
          note =>
            note.id !== currentNote.id &&
            note.tags?.some(tag => currentNote.tags?.includes(tag))
        )
      : [];

    return { backlinks, references, tagRelatedNotes };
  }, [currentNote.id, currentNote.references, currentNote.tags, allNotes]);

  // Memoize edge options
  const defaultEdgeOptions = useMemo(
    () => ({
      type: 'smoothstep',
      animated: true,
      style: { stroke: 'var(--edge-color, #64748b)' },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: 'var(--edge-color, #64748b)',
      },
    }),
    []
  );

  // Memoize node changes handler
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    []
  );

  // Memoize edge changes handler
  const onEdgesChange = useCallback(
    (changes: Edge[]) => {
      setEdges((eds) => eds.map(ed => ({ ...ed, ...changes.find(c => c.id === ed.id) })));
    },
    []
  );

  // Update graph elements when needed
  useEffect(() => {
    if (needsUpdate) {
      const { nodes: newNodes, edges: newEdges } = createElements(
        currentNote,
        allNotes,
        backlinks,
        references,
        tagRelatedNotes
      );

      setNodes(newNodes);
      setEdges(newEdges);
      setNeedsUpdate(false);
    }
  }, [currentNote.id, needsUpdate]);

  // Reset connection mode when switching notes
  useEffect(() => {
    setIsConnecting(false);
    setSelectedNode(null);
  }, [currentNote.id]);

  // Trigger update when references or backlinks change
  useEffect(() => {
    const referenceIds = new Set(references.map(note => note.id));
    const backlinkIds = new Set(backlinks.map(note => note.id));
    const tagNoteIds = new Set(tagRelatedNotes.map(note => note.id));
    
    const currentNodeIds = new Set(nodes
      .filter(node => node.data.section !== 'current')
      .map(node => node.data.noteId)
    );

    // Check if we need to update based on reference changes
    const needsRefresh = 
      Array.from(referenceIds).some(id => !currentNodeIds.has(id)) ||
      Array.from(backlinkIds).some(id => !currentNodeIds.has(id)) ||
      Array.from(tagNoteIds).some(id => !currentNodeIds.has(id)) ||
      Array.from(currentNodeIds).some(id => 
        !referenceIds.has(id) && 
        !backlinkIds.has(id) && 
        !tagNoteIds.has(id)
      );

    if (needsRefresh) {
      setNeedsUpdate(true);
    }
  }, [currentNote, references, backlinks, tagRelatedNotes, nodes]);

  // Watch for changes in note content and update references
  useEffect(() => {
    if (!currentNote || !onUpdateNote) return;

    // Extract [[links]] from note content
    const linkRegex = /\[\[(.*?)\]\]/g;
    const matches = Array.from(currentNote.content.matchAll(linkRegex));
    const linkedTitles = matches.map(match => match[1].trim());

    // Find notes with matching titles
    const linkedNoteIds = allNotes
      .filter(note => {
        const noteTitle = note.content.split('\n')[0].trim();
        return linkedTitles.includes(noteTitle);
      })
      .map(note => note.id);

    // If references have changed, update the note
    const currentRefs = currentNote.references || [];
    const hasChanges = 
      linkedNoteIds.length !== currentRefs.length ||
      linkedNoteIds.some(id => !currentRefs.includes(id)) ||
      currentRefs.some(id => !linkedNoteIds.includes(id));

    if (hasChanges) {
      const updatedNote = {
        ...currentNote,
        references: linkedNoteIds,
        last_modified: new Date().toISOString(),
      };
      onUpdateNote(updatedNote);
      setNeedsUpdate(true);
    }
  }, [currentNote?.content, allNotes, onUpdateNote]);

  // Handle node click in connection mode
  const handleNodeClick = useCallback(
    async (nodeId: string) => {
      if (!isConnecting) {
        onNodeClick?.(nodes.find(n => n.id === nodeId)?.data.noteId || null);
        return;
      }

      if (!selectedNode) {
        // First node selected
        setSelectedNode(nodeId);
        // Highlight the selected node
        setNodes(nodes.map(node => ({
          ...node,
          style: {
            ...node.style,
            border: node.id === nodeId ? '2px solid #3b82f6' : undefined,
          },
        })));
      } else {
        // Second node selected - create connection
        const sourceNode = nodes.find(n => n.id === selectedNode);
        const targetNode = nodes.find(n => n.id === nodeId);

        if (sourceNode && targetNode && sourceNode.id !== targetNode.id && onUpdateNote) {
          const sourceNoteId = sourceNode.data.noteId;
          const targetNoteId = targetNode.data.noteId;

          // Create a temporary edge for visual feedback
          const newEdge: Edge = {
            id: `temp-${sourceNode.id}-${targetNode.id}`,
            source: sourceNode.id,
            target: targetNode.id,
            type: 'smoothstep',
          };

          setEdges(eds => [...eds, newEdge]);

          try {
            // Get the note that should be updated (the source note)
            const noteToUpdate = allNotes.find(note => note.id === sourceNoteId);
            
            if (!noteToUpdate) {
              throw new Error('Source note not found');
            }

            // Update the note's references
            const updatedReferences = Array.from(
              new Set([...(noteToUpdate.references || []), targetNoteId])
            );

            const updatedNote = {
              ...noteToUpdate,
              references: updatedReferences,
              last_modified: new Date().toISOString(),
            };

            await onUpdateNote(updatedNote);

            // Update graph immediately for better feedback
            const newEdgeId = `reference-${sourceNoteId}-to-${targetNoteId}`;
            setEdges(eds => [
              ...eds.filter(e => e.id !== newEdge.id), // Remove temp edge
              {
                id: newEdgeId,
                source: `current-${sourceNoteId}`,
                target: `references-${targetNoteId}`,
                type: 'smoothstep',
              }
            ]);
            
            setNeedsUpdate(true);
          } catch (error) {
            console.error('Failed to update note:', error);
            // Remove the temporary edge if the update failed
            setEdges(eds => eds.filter(e => e.id !== newEdge.id));
          }
        }

        // Reset connection mode
        setSelectedNode(null);
        setIsConnecting(false);
        // Remove highlighting
        setNodes(nodes.map(node => ({
          ...node,
          style: {
            ...node.style,
            border: undefined,
          },
        })));
      }
    },
    [isConnecting, selectedNode, nodes, allNotes, onUpdateNote, onNodeClick]
  );

  // Handle node drag
  const onNodeDragStop = useCallback(
    async (event: React.MouseEvent, node: any) => {
      if (!onUpdateNote) return;

      const nodeX = node.position.x;
      const currentX = getSectionX('current');
      const referencesX = getSectionX('references');

      // Determine which section the node was dragged to
      const targetSection: Section = 
        nodeX < currentX - SECTION_WIDTH/2 ? 'backlinks' :
        nodeX < currentX + SECTION_WIDTH/2 ? 'current' :
        nodeX < referencesX + SECTION_WIDTH/2 ? 'references' : 'tags';

      // Only update if moving to/from references section
      if (
        (targetSection === 'references' && node.data.section !== 'references') ||
        (targetSection !== 'references' && node.data.section === 'references')
      ) {
        const isAddingReference = targetSection === 'references';
        const noteId = node.data.noteId;
        const currentReferences = currentNote.references || [];

        // Update references list without using Set
        const updatedReferences = isAddingReference
          ? Array.from(new Set(currentReferences.concat(noteId)))
          : currentReferences.filter(id => id !== noteId);

        try {
          // Update note with new references
          const updatedNote = {
            ...currentNote,
            references: updatedReferences,
            last_modified: new Date().toISOString()
          };
          
          await onUpdateNote(updatedNote);
          setNeedsUpdate(true);
        } catch (error) {
          console.error('Failed to update note:', error);
        }
      }
    },
    [currentNote, onUpdateNote]
  );

  // Handle reference removal
  useEffect(() => {
    const handleRemoveReference = async (e: Event) => {
      if (!onUpdateNote) return;

      const event = e as CustomEvent<{ noteId: string }>;
      const referenceToRemove = event.detail.noteId;
      
      try {
        // Update the current note's references
        const updatedReferences = (currentNote.references || [])
          .filter(id => id !== referenceToRemove);

        const updatedNote = {
          ...currentNote,
          references: updatedReferences,
          last_modified: new Date().toISOString(),
        };

        await onUpdateNote(updatedNote);
        setNeedsUpdate(true);
      } catch (error) {
        console.error('Failed to remove reference:', error);
      }
    };

    // Add event listener
    window.addEventListener('removeReference', handleRemoveReference);

    // Cleanup
    return () => {
      window.removeEventListener('removeReference', handleRemoveReference);
    };
  }, [currentNote, onUpdateNote]);

  return (
    <ReactFlowProvider>
      <div className="w-full h-full" ref={flowRef}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onNodeDragStop={onNodeDragStop}
          onNodeClick={(event, node) => {
            event.preventDefault();
            event.stopPropagation();
            handleNodeClick(node.id);
          }}
          nodeTypes={nodeTypes}
          fitView
          className="dark:bg-gray-900"
          defaultEdgeOptions={defaultEdgeOptions}
        >
          <Background 
            color="var(--bg-lines-color, #94a3b8)" 
            className="dark:bg-gray-900"
            gap={16} 
            size={1} 
          />
          <Controls className="dark:bg-gray-800 dark:border-gray-700 dark:shadow-xl" />
          <Panel position="top-right" className="dark:bg-gray-800 dark:border-gray-700">
            <button
              onClick={() => {
                if (isConnecting) {
                  // Reset connection mode
                  setSelectedNode(null);
                  setNodes(nodes.map(node => ({
                    ...node,
                    style: {
                      ...node.style,
                      border: undefined,
                    },
                  })));
                }
                setIsConnecting(!isConnecting);
              }}
              className={`px-3 py-1 rounded ${
                isConnecting 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              {isConnecting 
                ? selectedNode 
                  ? 'Select target note' 
                  : 'Select source note'
                : 'Connect Notes'
              }
            </button>
          </Panel>
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  );
};

export default NoteGraph;
