import React, { useState, useEffect } from 'react';
import type { Note } from '../../types';
import { CodeBlockComponent } from './CodeBlock';

interface CodeBlockWrapperProps {
  code: string;
  language: string;
  blockId: string;
  note: Note;
  onUpdate: (note: Note) => void;
}

export const CodeBlockWrapper: React.FC<CodeBlockWrapperProps> = ({
  code,
  language,
  blockId,
  note,
  onUpdate,
}) => {
  const [isExecuting, setIsExecuting] = useState(false);
  const [output, setOutput] = useState<string | undefined>(note.code_outputs?.[blockId]?.output);
  const [error, setError] = useState<string | undefined>(note.code_outputs?.[blockId]?.stderr);
  const isOutputBlock = blockId.startsWith('output-block-');

  // Update local state when note changes
  useEffect(() => {
    if (!isOutputBlock) {
      setOutput(note.code_outputs?.[blockId]?.output);
      setError(note.code_outputs?.[blockId]?.stderr);
    }
  }, [note.code_outputs, blockId, isOutputBlock]);

  const handleRunCode = async () => {
    if (isExecuting || isOutputBlock) return;
    
    setIsExecuting(true);
    setError(undefined);
    
    try {
      const response = await fetch('/api/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          language: language.toLowerCase(),
          noteId: note.id,
          blockId
        })
      });

      const result = await response.json();
      
      // Immediately update local state
      const newOutput = result.output || undefined;
      const newError = result.error || undefined;
      setOutput(newOutput);
      setError(newError);
      setIsExecuting(false);

      // Create new code outputs object preserving existing outputs
      const newCodeOutputs = {
        ...(note.code_outputs || {}),
        [blockId]: {
          output: newOutput,
          stderr: newError,
          timestamp: new Date().toISOString(),
          codeBlock: {
            content: code,
            language,
            lineNumber: -1
          }
        }
      };

      // Update note with execution results while preserving all fields
      const updatedNote = {
        ...note,
        code_outputs: newCodeOutputs,
        last_modified: new Date().toISOString()
      };
      
      // Update note state
      onUpdate(updatedNote);

    } catch (error) {
      console.error('Error executing code:', error);
      const errorMsg = 'Error executing code. Please try again.';
      
      // Immediately update local state
      setOutput(undefined);
      setError(errorMsg);
      setIsExecuting(false);

      // Create new code outputs object preserving existing outputs
      const newCodeOutputs = {
        ...(note.code_outputs || {}),
        [blockId]: {
          output: undefined,
          stderr: errorMsg,
          timestamp: new Date().toISOString(),
          codeBlock: {
            content: code,
            language,
            lineNumber: -1
          }
        }
      };

      // Update note with error state
      const updatedNote = {
        ...note,
        code_outputs: newCodeOutputs,
        last_modified: new Date().toISOString()
      };
      onUpdate(updatedNote);
    }
  };

  return (
    <CodeBlockComponent
      code={isOutputBlock ? (output || '') : code}
      language={isOutputBlock ? 'plaintext' : (language || 'text')}  
      output={isOutputBlock ? undefined : output}
      error={isOutputBlock ? undefined : error}
      isExecuting={isExecuting}
      onRun={isOutputBlock ? undefined : handleRunCode}
    />
  );
};
