import axiosInstance from '../services/axiosConfig';
import { AxiosError, isAxiosError } from 'axios';
import { Task } from '../types/task';
import { API_URL } from '../config';

const API_BASE_URL = API_URL;

interface ApiResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

const handleApiError = (error: unknown, defaultMessage: string): never => {
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError<ApiResponse<unknown>>;
    if (axiosError.response?.data?.error) {
      throw new Error(axiosError.response.data.error);
    }
    if (axiosError.response?.status === 404) {
      throw new Error('Resource not found');
    }
    if (axiosError.response?.status === 500) {
      throw new Error('Server error. Please try again later.');
    }
  }
  throw new Error(defaultMessage);
};

const retry = async <T>(fn: () => Promise<T>, retries = 3): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (retries > 0) {
      return retry(fn, retries - 1);
    }
    throw error;
  }
};

export const taskApi = {
  getAllTasks: async (): Promise<Task[]> => {
    try {
      const response = await retry(() => axiosInstance.get<ApiResponse<Task[]>>(`${API_BASE_URL}/tasks`));
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch tasks');
      }
      return response.data.data || [];
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return [];
      }
      console.error('Error fetching tasks:', error);
      throw handleApiError(error, 'Failed to fetch tasks. Please try again.');
    }
  },

  getTasksForCalendar: async (startDate: string, endDate: string): Promise<Task[]> => {
    try {
      const response = await retry(() => axiosInstance.get<ApiResponse<Task[]>>(`${API_BASE_URL}/tasks/calendar/${startDate}/${endDate}`));
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to fetch calendar tasks');
      }
      return response.data.data || [];
    } catch (error) {
      console.error('Error fetching calendar tasks:', error);
      throw handleApiError(error, 'Failed to fetch calendar tasks. Please try again.');
    }
  },

  createTask: async (task: Partial<Task>): Promise<Task> => {
    try {
      const response = await retry(() => axiosInstance.post<ApiResponse<Task>>(`${API_BASE_URL}/tasks`, task));
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to create task');
      }
      return response.data.data;
    } catch (error) {
      console.error('Error creating task:', error);
      throw handleApiError(error, 'Failed to create task. Please try again.');
    }
  },

  updateTask: async (taskId: string, updates: Partial<Task>): Promise<Task> => {
    try {
      const response = await retry(() => axiosInstance.put<ApiResponse<Task>>(`${API_BASE_URL}/tasks/${taskId}`, updates));
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to update task');
      }
      return response.data.data;
    } catch (error) {
      console.error('Error updating task:', error);
      throw handleApiError(error, 'Failed to update task. Please try again.');
    }
  },

  deleteTask: async (taskId: string): Promise<void> => {
    try {
      const response = await retry(() => axiosInstance.delete<ApiResponse<void>>(`${API_BASE_URL}/tasks/${taskId}`));
      if (!response.data.success && response.status !== 204) {
        throw new Error(response.data.error || 'Failed to delete task');
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      throw handleApiError(error, 'Failed to delete task. Please try again.');
    }
  }
};
