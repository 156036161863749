import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'katex/dist/katex.min.css';
import { Note } from '../../types';
import { CodeBlock } from '../../types/Note';
import { useKeyboardShortcuts } from '../../hooks/useKeyboardShortcuts';
import { Backlinks } from '../Backlinks';
import { TagEditor } from '../TagEditor/TagEditor';
import { CodeBlockWrapper } from '../CodeBlock/CodeBlockWrapper';
import { Trash2 } from 'react-feather';
import { Paragraph } from '../Paragraph/Paragraph';
import { SearchModal } from '../Search/SearchModal';
import { TableOfContents } from '../TableOfContents/TableOfContents';
import { AIAssistant } from '../AIAssistant/AIAssistant';

interface BacklinksProps {
  notes: Note[];
  currentNoteId: string;
  onSelect: (noteId: string) => void;
}

interface NotebookProps {
  note: Note;
  notes: Note[];
  backlinks: Note[];
  onUpdate: (note: Note) => void;
  onDelete?: () => void;
  onNoteClick: (noteId: string) => void;
}

interface SavedCodeOutput {
  output?: string;
  stderr?: string;
  timestamp: string;
  codeBlock: {
    content: string;
    language: string;
    lineNumber: number;
  };
}

interface SavedOutputs {
  [key: string]: SavedCodeOutput;
}

interface SuggesterPosition {
  x: number;
  y: number;
}

interface CodeBlockState {
  [key: string]: {
    isExecuting: boolean;
    output: string;
  };
}

interface CodeBlockExecution {
  output: string;
  error?: string;
  timestamp: string;
  language: string;
  content: string;
}

const codeExecutionApi = {
  executeCode: async (code: string, language: string, noteId: string, blockId: string) => {
    try {
      const response = await fetch('/api/execute', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          language,
          noteId,
          blockId,
        }),
      });

      if (!response.ok) {
        throw new Error('Execution failed');
      }

      const result = await response.json();
      console.log('Execution result:', result); 
      return {
        output: result.output || undefined,
        stderr: result.stderr || undefined,
      };
    } catch (error) {
      console.error('Caught error in executeCode:', {
        name: error instanceof Error ? error.name : 'Unknown error',
        message: error instanceof Error ? error.message : 'Unknown error',
      });

      return {
        stderr: error instanceof Error ? error.message : 'Failed to execute code. Please try again.',
      };
    }
  },
};

const extractCodeBlocks = (content: string): CodeBlock[] => {
  console.log('Content to parse:', content);  // Debug log
  
  // Updated regex to handle code blocks with proper language and content
  const codeBlockRegex = /```(\w+)[\r\n]+([\s\S]*?)```/g;
  const outputBlockRegex = /`out\s+([\s\S]*?)`/g;
  const blocks: CodeBlock[] = [];
  let match;
  let index = 0;

  // First extract standard code blocks
  console.log('Looking for code blocks...');  // Debug log
  while ((match = codeBlockRegex.exec(content)) !== null) {
    console.log('Found match:', match);  // Debug log
    const blockId = `code-block-${index++}`;
    const language = match[1].trim().toLowerCase();
    const codeContent = match[2].trim();
    console.log('Extracted:', { language, codeContent });  // Debug log
    
    blocks.push({
      blockId,
      language,
      content: codeContent,
    });
  }

  // Then extract output blocks
  while ((match = outputBlockRegex.exec(content)) !== null) {
    const blockId = `output-block-${index++}`;
    blocks.push({
      blockId,
      language: 'plaintext',
      content: '',  // Empty content since this is an output block
      output: match[1].trim(),  // Store the output in the output field
    });
  }

  console.log('Final blocks:', blocks);  // Debug log
  return blocks;
};

const Notebook: React.FC<NotebookProps> = ({ note, notes, backlinks, onUpdate, onDelete, onNoteClick }) => {
  const [localNote, setLocalNote] = useState(note);
  const [paragraphs, setParagraphs] = useState<string[]>(note.content ? note.content.split('\n******\n') : ['']);
  const [editingParagraphIndex, setEditingParagraphIndex] = useState<number | null>(null);
  const [codeBlocks, setCodeBlocks] = useState<CodeBlockState>({});
  const [lastSavedContent, setLastSavedContent] = useState(note.content || '');
  const [isDirty, setIsDirty] = useState(false);
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isTocCollapsed, setIsTocCollapsed] = useState(true);
  const [tocWidth, setTocWidth] = useState(264);

  const handleTocWidthChange = useCallback((width: number) => {
    setTocWidth(width);
  }, []);

  // Sync with external note changes
  useEffect(() => {
    setLocalNote(note);
    setParagraphs(note.content ? note.content.split('\n******\n') : ['']);
    setLastSavedContent(note.content || '');
    setIsDirty(false);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
  }, [note]);

  // Reset state when note changes
  useEffect(() => {
    setParagraphs(note.content ? note.content.split('\n******\n') : ['']);
    setLastSavedContent(note.content || '');
    setIsDirty(false);
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
  }, [note.id, note.content]); // Only run when note ID or content changes

  // Save changes when content changes
  useEffect(() => {
    const currentContent = paragraphs.join('\n******\n');
    if (currentContent !== lastSavedContent) {
      setIsDirty(true);
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
      
      saveTimeoutRef.current = setTimeout(() => {
        const updatedNote = {
          ...note,
          content: currentContent,
          last_modified: new Date().toISOString(),
          code_outputs: note.code_outputs || {}, // Preserve code outputs
          tags: localNote.tags || [],
          references: note.references || [],
          backlinks: note.backlinks || []
        };
        onUpdate(updatedNote);
        setLastSavedContent(currentContent);
        setIsDirty(false);
      }, 1000); // Increased debounce time to avoid conflicts with code execution
    }

    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [paragraphs, lastSavedContent, note, onUpdate, localNote]);

  // Save changes when unmounting
  useEffect(() => {
    return () => {
      const currentContent = paragraphs.join('\n******\n');
      if (currentContent !== lastSavedContent) {
        const updatedNote = {
          ...note,
          content: currentContent,
          last_modified: new Date().toISOString(),
          code_outputs: note.code_outputs || {}, // Preserve code outputs
          tags: localNote.tags || [],
          references: note.references || [],
          backlinks: note.backlinks || []
        };
        onUpdate(updatedNote);
      }
    };
  }, [paragraphs, note, onUpdate, lastSavedContent, localNote]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        setIsSearchOpen(true);
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const renderCodeBlock = useCallback((code: string, language: string, index: number) => {
    const blockId = `${note.id}-${index}-${language}`;
    
    return (
      <CodeBlockWrapper
        key={blockId}
        code={code}
        language={language}
        blockId={blockId}
        note={note}
        onUpdate={onUpdate}
      />
    );
  }, [note, onUpdate]);

  const splitContentIntoParagraphs = useCallback((content: string): string[] => {
    if (!content || content.trim() === '') return [''];
    
    // First split by our special paragraph delimiter
    const rawParagraphs = content.split('\n******\n');
    
    // Then process each potential paragraph
    const processedParagraphs: string[] = [];
    let currentParagraph = '';
    let inCodeBlock = false;
    
    for (const rawParagraph of rawParagraphs) {
      const lines = rawParagraph.split('\n');
      
      for (const line of lines) {
        const isCodeBlockMarker = line.trim().startsWith('```');
        
        if (isCodeBlockMarker) {
          if (!inCodeBlock) {
            // Start of code block
            if (currentParagraph.trim()) {
              processedParagraphs.push(currentParagraph.trim());
              currentParagraph = '';
            }
            inCodeBlock = true;
          } else {
            // End of code block
            inCodeBlock = false;
          }
          currentParagraph += (currentParagraph ? '\n' : '') + line;
        } else {
          currentParagraph += (currentParagraph ? '\n' : '') + line;
          
          if (!inCodeBlock && line.trim() === '') {
            if (currentParagraph.trim()) {
              processedParagraphs.push(currentParagraph.trim());
              currentParagraph = '';
            }
          }
        }
      }
      
      // Handle any remaining content in the current raw paragraph
      if (currentParagraph.trim() && !inCodeBlock) {
        processedParagraphs.push(currentParagraph.trim());
        currentParagraph = '';
      }
    }
    
    // Handle any final remaining content
    if (currentParagraph.trim()) {
      processedParagraphs.push(currentParagraph.trim());
    }
    
    return processedParagraphs.length > 0 ? processedParagraphs : [''];
  }, []);

  const extractTitle = useCallback((content: string[]) => {
    // Find first line starting with # anywhere in the content
    const titleLine = content.find(line => line.trim().startsWith('#'));
    if (titleLine) {
      // Remove # and any whitespace
      return titleLine.replace(/^\s*#+\s*/, '').trim();
    }
    return 'Untitled';
  }, []);

  const handleContentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    const newParagraphs = splitContentIntoParagraphs(newContent);
    setParagraphs(newParagraphs);

    // Update note with new content and title
    onUpdate({
      ...note,
      title: extractTitle(newParagraphs),
      content: newContent,
      last_modified: new Date().toISOString()
    });
  }, [note, onUpdate, extractTitle]);

  const handleParagraphEdit = useCallback((index: number, newContent: string) => {
    const newParagraphs = [...paragraphs];
    newParagraphs[index] = newContent;

    // If this is the last paragraph and has content, add a new empty paragraph
    if (index === paragraphs.length - 1 && newContent.trim() !== '') {
      newParagraphs.push('');
    }

    // Remove empty paragraphs in the middle, but always keep at least one paragraph
    const filteredParagraphs = newParagraphs.filter((p, i) => 
      p.trim() !== '' || i === newParagraphs.length - 1 || i === editingParagraphIndex
    );
    
    if (filteredParagraphs.length > 0) {
      setParagraphs(filteredParagraphs);
      
      // Update note with new content and title
      const newTitle = extractTitle(filteredParagraphs);
      onUpdate({
        ...note,
        title: newTitle,
        content: filteredParagraphs.join('\n******\n'),
        last_modified: new Date().toISOString()
      });
    }
  }, [paragraphs, note, onUpdate, editingParagraphIndex, extractTitle]);

  const handleParagraphDelete = useCallback((index: number) => {
    const newParagraphs = [...paragraphs];
    newParagraphs.splice(index, 1);

    // Always ensure there's at least one paragraph
    if (newParagraphs.length === 0) {
      newParagraphs.push('');
      setEditingParagraphIndex(0);
    } else {
      setEditingParagraphIndex(Math.max(0, index - 1));
    }

    setParagraphs(newParagraphs);
    onUpdate({
      ...note,
      title: extractTitle(newParagraphs),
      content: newParagraphs.join('\n******\n'),
      last_modified: new Date().toISOString()
    });
  }, [paragraphs, note, onUpdate, extractTitle]);

  const handleParagraphBlur = useCallback(() => {
    // Save current state before clearing editing state
    const newTitle = extractTitle(paragraphs);
    onUpdate({
      ...note,
      title: newTitle,
      content: paragraphs.join('\n******\n'),
      last_modified: new Date().toISOString()
    });

    // Don't clear editing state immediately to allow for paragraph navigation
    setTimeout(() => {
      if (document.activeElement?.tagName !== 'TEXTAREA') {
        setEditingParagraphIndex(null);
      }
    }, 100);
  }, [paragraphs, note, onUpdate, extractTitle]);

  const handleParagraphKeyDown = useCallback((e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const textarea = e.target as HTMLTextAreaElement;
      const { selectionStart } = textarea;
      const currentContent = paragraphs[index];
      const beforeCursor = currentContent.slice(0, selectionStart);
      const afterCursor = currentContent.slice(selectionStart);

      const newParagraphs = [...paragraphs];
      newParagraphs[index] = beforeCursor;
      newParagraphs.splice(index + 1, 0, afterCursor);
      setParagraphs(newParagraphs);
      setEditingParagraphIndex(index + 1);

      // Save immediately on Enter with updated title
      const newTitle = extractTitle(newParagraphs);
      onUpdate({
        ...note,
        title: newTitle,
        content: newParagraphs.join('\n******\n'),
        last_modified: new Date().toISOString()
      });
    }
  }, [paragraphs, note, onUpdate, extractTitle]);

  const handleParagraphClick = useCallback((index: number) => {
    if (editingParagraphIndex === index) return;
    
    // Get the event from the window.event object
    const event = window.event as MouseEvent;
    const target = event?.target as HTMLElement;
    
    // Don't enter edit mode if the click was on the run button
    if (target?.closest('.code-run-button')) return;
    
    setEditingParagraphIndex(index);
  }, [editingParagraphIndex]);

  const handleInput = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    handleContentChange(e);

    const cursorPos = e.target.selectionStart;
    const textArea = e.target;
    const textBeforeCursor = e.target.value.substring(0, cursorPos);

    const lastOpenBrackets = textBeforeCursor.lastIndexOf('[[');

    if (lastOpenBrackets !== -1 && !textBeforeCursor.includes(']]', lastOpenBrackets)) {
      const query = textBeforeCursor.substring(lastOpenBrackets + 2);
      // setLinkQuery(query);

      const scrollTop = textArea.scrollTop;
      const scrollLeft = textArea.scrollLeft;

      const mirror = document.createElement('div');
      mirror.style.cssText = window.getComputedStyle(textArea).cssText;
      mirror.style.height = 'auto';
      mirror.style.width = `${textArea.clientWidth}px`;
      mirror.style.position = 'absolute';
      mirror.style.top = '0';
      mirror.style.left = '0';
      mirror.style.visibility = 'hidden';
      mirror.style.whiteSpace = 'pre-wrap';
      mirror.style.wordWrap = 'break-word';
      mirror.style.paddingTop = '0';
      mirror.style.paddingBottom = '0';
      mirror.style.borderTop = '0';
      mirror.style.borderBottom = '0';

      const textUpToCursor = document.createTextNode(textBeforeCursor);
      mirror.appendChild(textUpToCursor);

      const cursorSpan = document.createElement('span');
      cursorSpan.textContent = '|';
      mirror.appendChild(cursorSpan);

      document.body.appendChild(mirror);

      const rect = textArea.getBoundingClientRect();
      const spanRect = cursorSpan.getBoundingClientRect();
      const mirrorRect = mirror.getBoundingClientRect();

      document.body.removeChild(mirror);

      const x = rect.left + (spanRect.left - mirrorRect.left) - scrollLeft;
      const y = rect.top + (spanRect.top - mirrorRect.top) - scrollTop;

      // setSuggesterPosition({
      //   x,
      //   y: y + parseInt(getComputedStyle(textArea).lineHeight)
      // });
      // setShowSuggester(true);
    } else {
      // setShowSuggester(false);
    }
  }, [handleContentChange]);

  const handleTagAddition = useCallback((newTag: string) => {
    const normalizedTag = newTag.trim().replace(/^#|[\[\]]/g, '');
    
    if (normalizedTag && !localNote.tags?.includes(normalizedTag)) {
      const updatedNote = {
        ...localNote,
        tags: [...(localNote.tags || []), normalizedTag],
        last_modified: new Date().toISOString()
      };
      setLocalNote(updatedNote);
      onUpdate(updatedNote);
    }
  }, [localNote, onUpdate]);

  const handleTagRemoval = useCallback((tagToRemove: string) => {
    const updatedNote = {
      ...localNote,
      tags: (localNote.tags || []).filter(tag => tag !== tagToRemove),
      last_modified: new Date().toISOString()
    };
    setLocalNote(updatedNote);
    onUpdate(updatedNote);
  }, [localNote, onUpdate]);

  const handleTagsChange = useCallback((newTags: string[]) => {
    const updatedNote = { 
      ...localNote, 
      tags: newTags,
      last_modified: new Date().toISOString()
    };
    setLocalNote(updatedNote);
    onUpdate(updatedNote);
  }, [localNote, onUpdate]);

  const handleSave = useCallback(() => {
    const currentContent = paragraphs.join('\n******\n');
    onUpdate({
      ...note,
      title: extractTitle(paragraphs),
      content: currentContent,
      last_modified: new Date().toISOString()
    });
  }, [note, paragraphs, onUpdate, extractTitle]);

  const toggleEdit = useCallback(() => {
    // No-op for now
  }, []);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 's') {
      e.preventDefault();
      handleSave();
    }
  }, [handleSave]);

  const handleHeaderClick = useCallback((lineIndex: number) => {
    // Find the paragraph that contains this line
    let currentLine = 0;
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraphLines = paragraphs[i].split('\n').length;
      if (currentLine + paragraphLines > lineIndex) {
        setEditingParagraphIndex(i);
        // Scroll the paragraph into view
        setTimeout(() => {
          const element = document.querySelector(`[data-paragraph-index="${i}"]`);
          element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0);
        return;
      }
      currentLine += paragraphLines + 1; // +1 for the paragraph separator
    }
  }, [paragraphs]);

  const handleToggleToc = useCallback(() => {
    setIsTocCollapsed(prev => !prev);
  }, []);

  useKeyboardShortcuts({
    onToggleEdit: toggleEdit
  });

  const onUpdateNote = useCallback((note: Note) => {
    onUpdate(note);
  }, [onUpdate]);

  return (
    <>
      <div className="flex h-full bg-gray-50 dark:bg-gray-900">
        {/* Table of Contents - fixed position */}
        <aside 
          className={`fixed top-16 left-0 bottom-0 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 overflow-y-auto transition-all duration-300 ease-in-out ${
            isTocCollapsed ? 'w-12' : ''
          }`}
          style={{ width: isTocCollapsed ? 48 : tocWidth }}
        >
          <div className="p-4">
            <TableOfContents
              content={note.content}
              onHeaderClick={handleHeaderClick}
              isCollapsed={isTocCollapsed}
              onToggleCollapse={() => setIsTocCollapsed(!isTocCollapsed)}
              note={note}
              onTagAdd={handleTagAddition}
              onTagRemove={handleTagRemoval}
              onTagsChange={handleTagsChange}
            />
          </div>
          {/* Resize handle */}
          {!isTocCollapsed && (
            <div
              className="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize hover:bg-blue-500"
              onMouseDown={(e) => {
                e.preventDefault();
                const startX = e.pageX;
                const startWidth = tocWidth;
                
                const handleMouseMove = (e: MouseEvent) => {
                  const newWidth = startWidth + (e.pageX - startX);
                  if (newWidth >= 200 && newWidth <= 400) {
                    setTocWidth(newWidth);
                  }
                };
                
                const handleMouseUp = () => {
                  document.removeEventListener('mousemove', handleMouseMove);
                  document.removeEventListener('mouseup', handleMouseUp);
                };
                
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
              }}
            />
          )}
        </aside>

        {/* Main content - scrollable with proper margin for fixed sidebar */}
        <main className={`flex-1 overflow-y-auto transition-all duration-300 ease-in-out ${
          isTocCollapsed ? 'ml-12' : 'ml-[264px]'
        }`}>
          <div className="max-w-4xl mx-auto px-8 py-6 min-h-screen bg-gray-50 dark:bg-gray-900">
            {/* Note header with tags and actions */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-4">
                <TagEditor
                  tags={localNote.tags || []}
                  onAdd={handleTagAddition}
                  onRemove={handleTagRemoval}
                />
                {onDelete && (
                  <button
                    onClick={onDelete}
                    className="ml-4 p-2 text-gray-500 hover:text-red-500 dark:text-gray-400 dark:hover:text-red-400 transition-colors rounded-md hover:bg-gray-100 dark:hover:bg-gray-800"
                    title="Delete note"
                  >
                    <Trash2 size={16} />
                  </button>
                )}
              </div>
            </div>

            {/* Note content */}
            <div className="space-y-4">
              {paragraphs.map((content, index) => (
                <Paragraph
                  key={index}
                  index={index}
                  content={content}
                  isEditing={editingParagraphIndex === index}
                  onClick={handleParagraphClick}
                  onEdit={handleParagraphEdit}
                  onDelete={() => handleParagraphDelete(index)}
                  onBlur={handleParagraphBlur}
                  onKeyDown={handleParagraphKeyDown}
                  renderCodeBlock={renderCodeBlock}
                  totalParagraphs={paragraphs.length}
                  setEditingParagraphIndex={setEditingParagraphIndex}
                  data-paragraph-index={index}
                />
              ))}
            </div>
          </div>

          {backlinks.length > 0 && (
            <div className="border-t border-gray-200 dark:border-gray-700">
              <Backlinks notebook={{ notes }} currentNoteId={note.id} onSelect={onNoteClick} />
            </div>
          )}
        </main>
      </div>

      {/* Search modal */}
      <SearchModal
        show={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        notes={notes}
        onNoteSelect={onNoteClick}
      />

      <AIAssistant
        currentNote={note}
        allNotes={notes}
        onInsertContent={(content) => {
          const newContent = note.content + '\n\n' + content;
          onUpdateNote({ ...note, content: newContent });
        }}
        onAddTags={(tags) => {
          const newTags = Array.from(new Set(note.tags.concat(tags)));
          onUpdateNote({ ...note, tags: newTags });
        }}
      />
    </>
  );
};

export default Notebook;
